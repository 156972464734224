import '../styles/pages/academy.scss';
import * as React from "react"
import {HeadFC, navigate, Link} from "gatsby"
import {useEffect, useState} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";
import {
    BigLogoImage,
    LoadingGif,
    AcademyBg,
    AcademyBanner,
    AcademyLogo,
    academyIcon1,
    academyIcon2,
    academyIcon3,
    academyLaptop,
    AcademyBgMob,
    academyMobile,
    academyUser1,
    academyUser2,
    academyUser3,
    academyUser4,
    academyUser5,
    academyUser6,
    academyUser7,
    academyUser8,
    academyLearnIcon1,
    academyLearnIcon2,
    academyLearnIcon3,
    academyLearnIcon4,
    academyLearnIcon5,
    academyLearnIcon6,
    academyLearnIcon7,
    academyLearnIcon8,
    academyLearn1,
    academyLearn2,
    academyLearn3,
    academyLearn4,
    academyLearn5,
    academyLearn6,
    academyLearn7,
    academyLearn8,
    academyRev1,
    academyRev2,
    academyRev3,
    academyRev4,
    academyRev5,
    academyRev6,
    academyRev7,
    academyRev8,
    academyRev9,
    academyRev10,
    academyRev11,
    academyRev12,
    academyRev13,
    academyRev14,
    academyRev15,
    academyRev16,
    AcademyLogo2,
    academyLock,
    academyMan1,
    academyMan2,
    academyMan3,
    academyMan4,
    academyMan5,
    academyMan6,
    academyAccess1,
    academyAccess2,
    AcademyLogo3,
    academyWarning,
    linkedIn,
    instagram,
    academyOwner,
    BigLogoRight,
    AvatarImage,
    masterCardV2,
    VisaV2,
    academyAppstore,
    academyGplay,
    academyMoon,
    academyHeaderLogo,
    academyToggle,
    academyMobMenuClose
} from "../images";
import AcademyFAQItem from '../components/AcademyFAQItem';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import bg from 'date-fns/esm/locale/bg/index.js';

// import useAnalytics from "../hooks/useAnalytics";

const questions = [
    {
        id: 1,
        question: 'How quickly will I make my money back?',
        answer: 'It depends on how seriously you take entrepreneurship. <br /><br /> But many students made their money back in a couple of weeks. use freelancing or reselling traffic to websites.  <br /><br /> Note: Everything taught within FeelGrow Platform is for education purposes only.  <br /><br /> It is up to each student to implement and do the work. The FeelGrow team doesn’t guarantee any profits or financial success.',
    },
    {
        id: 2,
        question: "Do I need money once I'm inside FeelGrow Platform?",
        answer: 'It depends on how seriously you take entrepreneurship. <br /><br /> But many students made their money back in a couple of weeks. use freelancing or reselling traffic to websites.  <br /><br /> Note: Everything taught within FeelGrow Platform is for education purposes only.  <br /><br /> It is up to each student to implement and do the work. The FeelGrow team doesn’t guarantee any profits or financial success.',
    },
    {
        id: 3,
        question: 'Does my age really not matter?',
        answer: 'It depends on how seriously you take entrepreneurship. <br /><br /> But many students made their money back in a couple of weeks. use freelancing or reselling traffic to websites.  <br /><br /> Note: Everything taught within FeelGrow Platform is for education purposes only.  <br /><br /> It is up to each student to implement and do the work. The FeelGrow team doesn’t guarantee any profits or financial success.',
    },
    {
        id: 4,
        question: 'I know nothing about the skills you teach. Is it a problem?',
        answer: 'It depends on how seriously you take entrepreneurship. <br /><br /> But many students made their money back in a couple of weeks. use freelancing or reselling traffic to websites.  <br /><br /> Note: Everything taught within FeelGrow Platform is for education purposes only.  <br /><br /> It is up to each student to implement and do the work. The FeelGrow team doesn’t guarantee any profits or financial success.',
    },
    {
        id: 5,
        question: "I don't have a lot of time avaliable, can I still apply?",
        answer: 'It depends on how seriously you take entrepreneurship. <br /><br /> But many students made their money back in a couple of weeks. use freelancing or reselling traffic to websites.  <br /><br /> Note: Everything taught within FeelGrow Platform is for education purposes only.  <br /><br /> It is up to each student to implement and do the work. The FeelGrow team doesn’t guarantee any profits or financial success.',
    },
    {
        id: 6,
        question: 'I live in X country. Is it a problem?',
        answer: 'It depends on how seriously you take entrepreneurship. <br /><br /> But many students made their money back in a couple of weeks. use freelancing or reselling traffic to websites.  <br /><br /> Note: Everything taught within FeelGrow Platform is for education purposes only.  <br /><br /> It is up to each student to implement and do the work. The FeelGrow team doesn’t guarantee any profits or financial success.',
    },
    {
        id: 7,
        question: 'Will I get access to all the courses once I join?',
        answer: 'It depends on how seriously you take entrepreneurship. <br /><br /> But many students made their money back in a couple of weeks. use freelancing or reselling traffic to websites.  <br /><br /> Note: Everything taught within FeelGrow Platform is for education purposes only.  <br /><br /> It is up to each student to implement and do the work. The FeelGrow team doesn’t guarantee any profits or financial success.',
    },
    {
        id: 8,
        question: 'Still have questions?',
        answer: 'It depends on how seriously you take entrepreneurship. <br /><br /> But many students made their money back in a couple of weeks. use freelancing or reselling traffic to websites.  <br /><br /> Note: Everything taught within FeelGrow Platform is for education purposes only.  <br /><br /> It is up to each student to implement and do the work. The FeelGrow team doesn’t guarantee any profits or financial success.',
    },
]

const academyPage = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    const [isLoading, setLoading] = useState(true);
    const [menuStatus, setMenuStatus] = useState(false);
    // const {initAnalytics, saveEmail} = useAnalytics();

    const handleMobileMenu = () => {
        setMenuStatus(!menuStatus);
    }

    const triggerLink = () => {
        fbq("track", "Lead");
        navigate('https://feeeeeeeel.com/click?o=11&a=3');
    }
    

    useEffect(() => {
        // initAnalytics();
        setTimeout(() => {
            setLoading(false);
            window.scrollBy(0, 1);
        }, 500);
    }, []);

    const css = `
        body {
            background-color: #0C0E13;
        }
    `;

    return (
        <main>
            <style>{css}</style>
            {
                isLoading && (
                    <div className="loadWrap">
                        <div className="loadWrapLogo">
                            <img width={180} src={BigLogoImage} alt=""/>
                            <div className="loadWrapText">
                                <img width={16} src={LoadingGif} alt=""/>
                                Loading..
                            </div>
                        </div>
                    </div>
                )
            }

            

            <section className="academyPage">

                <div className="academyToggle" onClick={() => handleMobileMenu()}>
                    <img src={academyToggle} alt="" />
                </div>

                <div className={`academyMobileMenu ${menuStatus ? 'active' : ''}`}>
                    <img src={academyMobMenuClose} alt="" className="academyMobileMenuClose"
                        onClick={() => handleMobileMenu()} />
                    <ul>
                        <li>
                            <a href="/academy/#access" onClick={() => handleMobileMenu()}>Access</a>
                        </li>
                        <li>
                            <a href="/academy/#education" onClick={() => handleMobileMenu()}>Education</a>
                        </li>
                        <li>
                            <a href="/academy/#results" onClick={() => handleMobileMenu()}>Results</a>
                        </li>
                        <li>
                            <a href="/academy/#about" onClick={() => handleMobileMenu()}>About</a>
                        </li>
                    </ul>
                    <a onClick={() => triggerLink()} className="academyHeaderJoin">Join now</a>
                </div>

                <div className={`academyMobileMenuOverlay ${menuStatus ? 'active' : ''}`} onClick={() => handleMobileMenu()}></div>

                <section className="academyFirstSection">
                    {/* <img src={AcademyBg} alt="" className="academyFirstSectionBg" /> */}
                    <picture className="academyFirstSectionBg">
                        <source srcSet={AcademyBg} media="(min-width: 768px)" />
                        <img src={AcademyBgMob} />
                    </picture>
            
                    <div className="academyHeader" data-aos-delay="500" data-aos="fade-down">
                        <div className="academyContainer">
                            <div className="academyHeaderBox">

                                <div className="academyHeaderLogo">
                                    <img src={academyHeaderLogo} alt="" />
                                </div>
                                
                                <nav className="academyHeaderNav">
                                    <ul>
                                        <li>
                                            <AnchorLink to="/academy/#access">Access</AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink to="/academy/#education">Education</AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink to="/academy/#results">Results</AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink to="/academy/#about">About</AnchorLink>
                                        </li>
                                    </ul>
                                </nav>
                                <a onClick={() => triggerLink()} className="academyHeaderJoin">Join now</a>
                                {/* <a href="" className="academyHeaderLogin">log in</a> */}
                            </div>
                        </div>
                    </div>
                    <div className="academyBanner">
                        <div className="academyContainer">
                            <h1 className="academyBannerTitle" data-aos-delay="500" data-aos="fade-up">
                                The <span>FeelGrow PLATFORM </span><br />
                                for early stage entrepreneurs 
                            </h1>
                            <p className="academyBannerSubtitle" data-aos-delay="700" data-aos="fade-up">Learn new skills every day, gain control of your life with complex development on FeelGrow Platform</p>
                            <img src={AcademyBanner} alt="" className="academyBannerAsset" data-aos-delay="1000" data-aos="fade-up" />
                            <a onClick={() => triggerLink()} className="academyBannerBtn">11,254+ have already taken advantage of this opportunity</a>
                        </div>
                    </div>
                </section>

                <section className="academyIntro">
                    <div className="academyContainer">
                        <div className="academyIntroBox">
                            <img src={AcademyLogo} alt="" className="academyIntroLogo" data-aos="fade-up" data-aos-offset="50" />
                            <span className="academyIntroSubtitle" data-aos="fade-up" data-aos-offset="50">INTRODUCING</span>
                            <h2 className="academyIntroTitle" data-aos="fade-up" data-aos-offset="50">A MASSIVE <span>UPGRADE</span></h2>
                            <div className="academyIntroText" data-aos="fade-up" data-aos-offset="50">
                                <p>The world's most advanced financial education platform just got even better.</p>
                                <p>'FeelGrow” is an educational platform designed for the development and training of entrepreneurs, poised to revolutionize the modern education system forever.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="academyAdv">
                    <div className="academyContainer">
                        <div className="academyAdvRow">
                            <div className="academyAdvInfo">
                                <div className="academyAdvHead" data-aos="fade-up" data-aos-offset="50">
                                    <img src={academyIcon1} alt="" className="academyAdvIcon" />
                                    <span className="academyAdvHeading">Convenient platform, courses, instructions, tutorials</span>
                                </div>
                                <ul className="academyAdvList">
                                    <li data-aos="fade-up" data-aos-offset="50">
                                        <span>World-class custom built learning application</span>
                                    </li>
                                    <li data-aos="fade-up" data-aos-offset="50">
                                        <span>Scale from Zero to $10k/month as fast as possible</span>
                                    </li>
                                    <li data-aos="fade-up" data-aos-offset="50">
                                        <span>Master the skills you need to maximize your income.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="academyAdvImage" data-aos="fade-left" data-aos-offset="50">
                                <img src={academyLaptop} alt="" />
                            </div>
                        </div>

                        <div className="academyAdvRow">
                            <div className="academyAdvUsers">
                                <div className="academyAdvUser">
                                    <img src={academyUser1} alt="" />
                                    <span>Dylan</span>
                                    <p>E-Commerce</p>
                                </div>
                                <div className="academyAdvUser">
                                    <img src={academyUser2} alt="" />
                                    <span>Benjamin</span>
                                    <p>Freelancing</p>
                                </div>
                                <div className="academyAdvUser">
                                    <img src={academyUser3} alt="" />
                                    <span>Robert</span>
                                    <p>Fitness</p>
                                </div>
                                <div className="academyAdvUser">
                                    <img src={academyUser4} alt="" />
                                    <span>Brayan</span>
                                    <p>Marketing</p>
                                </div>
                                
                                <div className="academyAdvUser">
                                    <img src={academyUser5} alt="" />
                                    <span>Charles</span>
                                    <p>Affiliat Program</p>
                                </div>
                                <div className="academyAdvUser">
                                    <img src={academyUser6} alt="" />
                                    <span>Chester</span>
                                    <p>Content</p>
                                </div>
                                <div className="academyAdvUser">
                                    <img src={academyUser7} alt="" />
                                    <span>Jacob</span>
                                    <p>App Developer</p>
                                </div>
                                <div className="academyAdvUser">
                                    <img src={academyUser8} alt="" />
                                    <span>Oscar</span>
                                    <p>Sturtups</p>
                                </div>
                            </div>
                            <div className="academyAdvInfo">
                                <div className="academyAdvHead" data-aos="fade-up" data-aos-offset="50">
                                    <img src={academyIcon2} alt="" className="academyAdvIcon" />
                                    <span className="academyAdvHeading">Private Network of entrepreneurs</span>
                                </div>
                                <ul className="academyAdvList">
                                    <li data-aos="fade-up" data-aos-offset="50">
                                        <span>Make like-minded friends on your journey</span>
                                    </li>
                                    <li data-aos="fade-up" data-aos-offset="50">
                                        <span>Find partners and investors</span>
                                    </li>
                                    <li data-aos="fade-up" data-aos-offset="50">
                                        <span>Network with 10,000+ people</span>
                                    </li>
                                </ul>
                            </div>
                            
                        </div>

                        <div className="academyAdvRow">
                            <div className="academyAdvInfo">
                                <div className="academyAdvHead" data-aos="fade-up" data-aos-offset="50">
                                    <img src={academyIcon3} alt="" className="academyAdvIcon" />
                                    <span className="academyAdvHeading">Personalized plan and mentoring</span>
                                </div>
                                <ul className="academyAdvList">
                                    <li data-aos="fade-up" data-aos-offset="50">
                                        <span>Content creators are hyper-successful experts in their field</span>
                                    </li>
                                    <li data-aos="fade-up" data-aos-offset="50">
                                        <span>Get mentored every step of your journey with our developed AI-assistant</span>
                                    </li>
                                    <li data-aos="fade-up" data-aos-offset="50">
                                        <span>You've got a clear plan, tasks, well-defined goals, summaries, notifications – all in your phone.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="academyAdvImage" data-aos="fade-left" data-aos-offset="50">
                                <img src={academyMobile} alt="" />
                            </div>
                        </div>
                    </div>
                    <img src={academyMoon} alt="" className="academyAdvMoon" />
                </section>

                <section className="academyLearn" id="education">
                    <div className="academyContainer">
                        <h2 className="academyLearnTitle" data-aos="fade-up" data-aos-offset="50">WHAT YOU WILL <span>LEARN</span></h2>
                        <div className="academyLearnBox">
                            <div className="academyLearnItem" data-aos="fade-right" data-aos-offset="50">
                                <div className="academyLearnHeading">
                                    <img src={academyLearnIcon1} alt="" />
                                    <span>E-Commerce</span>
                                </div>
                                <p className="academyLearnText">Sell profitable products online. Industry experts will train you to find winning products, create the perfect store, and generate traffic on your sites.</p>
                                <div className="academyLearnImage">
                                    <img src={academyLearn1} alt="" />
                                </div>
                            </div>

                            <div className="academyLearnItem" data-aos="fade-left" data-aos-offset="50">
                                <div className="academyLearnHeading">
                                    <img src={academyLearnIcon2} alt="" />
                                    <span>content creation </span>
                                </div>
                                <p className="academyLearnText">Learn from experts to craft compelling content that engages your audience and turns your skills into revenue streams.</p>
                                <div className="academyLearnImage">
                                    <img src={academyLearn2} alt="" />
                                </div>
                            </div>

                            <div className="academyLearnItem" data-aos="fade-right" data-aos-offset="50">
                                <div className="academyLearnHeading">
                                    <img src={academyLearnIcon3} alt="" />
                                    <span>affiliate marketing</span>
                                </div>
                                <p className="academyLearnText">Learn the strategies from industry leaders to drive traffic and convert clicks into commissions, turning your network into a profitable asset.</p>
                                <div className="academyLearnImage">
                                    <img src={academyLearn3} alt="" />
                                </div>
                            </div>

                            <div className="academyLearnItem" data-aos="fade-left" data-aos-offset="50">
                                <div className="academyLearnHeading">
                                    <img src={academyLearnIcon4} alt="" />
                                    <span>Client Acquisition</span>
                                </div>
                                <p className="academyLearnText">Gain insights from top professionals to identify and engage your target market, turning leads into loyal customers for sustainable growth.</p>
                                <div className="academyLearnImage">
                                    <img src={academyLearn4} alt="" />
                                </div>
                            </div>

                            <div className="academyLearnItem" data-aos="fade-right" data-aos-offset="50">
                                <div className="academyLearnHeading">
                                    <img src={academyLearnIcon5} alt="" />
                                    <span>Developing Applications </span>
                                </div>
                                <p className="academyLearnText"> Learn from seasoned developers to build user-friendly apps that solve real-world problems and generate sustainable revenue.</p>
                                <div className="academyLearnImage">
                                    <img src={academyLearn5} alt="" />
                                </div>
                            </div>

                            <div className="academyLearnItem" data-aos="fade-left" data-aos-offset="50">
                                <div className="academyLearnHeading">
                                    <img src={academyLearnIcon6} alt="" />
                                    <span>Sturtups </span>
                                </div>
                                <p className="academyLearnText">Get guidance from seasoned entrepreneurs to validate your idea, secure funding, and launch a profitable startup venture.</p>
                                <div className="academyLearnImage">
                                    <img src={academyLearn6} alt="" />
                                </div>
                            </div>

                            <div className="academyLearnItem" data-aos="fade-right" data-aos-offset="50">
                                <div className="academyLearnHeading">
                                    <img src={academyLearnIcon7} alt="" />
                                    <span>New skills</span>
                                </div>
                                <p className="academyLearnText">Acquire essential skills from trusted experts, broadening your capabilities to adapt and thrive in an ever-changing job market.</p>
                                <div className="academyLearnImage">
                                    <img src={academyLearn7} alt="" />
                                </div>
                            </div>

                            <div className="academyLearnItem" data-aos="fade-left" data-aos-offset="50">
                                <div className="academyLearnHeading">
                                    <img src={academyLearnIcon8} alt="" />
                                    <span>AI Technologies </span>
                                </div>
                                <p className="academyLearnText">Learn from industry experts to harness the power of artificial intelligence, transforming data into actionable insights for profitable growth.</p>
                                <div className="academyLearnImage">
                                    <img src={academyLearn8} alt="" />
                                </div>
                            </div>
                        </div>
                        <center>
                            <a onClick={() => triggerLink()} className="academyLearnBtn">Join feelgrow now</a>
                        </center>
                    </div>
                </section>

                <section className="academyReview" id="results">
                    <div className="academyContainer">
                        <h2 className="academyReviewTitle" data-aos="fade-up" data-aos-offset="50">
                            OUR STUDENTS’ <span>REVIEWS</span>
                        </h2>
                        <div className="academyReviewBox">
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev1} alt="" />
                            </div>
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev2} alt="" />
                            </div>
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev3} alt="" />
                            </div>
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev4} alt="" />
                            </div>
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev5} alt="" />
                            </div>
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev6} alt="" />
                            </div>
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev7} alt="" />
                            </div>
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev8} alt="" />
                            </div>
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev9} alt="" />
                            </div>
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev10} alt="" />
                            </div>
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev11} alt="" />
                            </div>
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev12} alt="" />
                            </div>
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev13} alt="" />
                            </div>
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev14} alt="" />
                            </div>
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev15} alt="" />
                            </div>
                            <div className="academyReviewItem" data-aos="fade-up" data-aos-offset="50">
                                <img src={academyRev16} alt="" />
                            </div>
                        </div>
                        <center>
                            <a onClick={() => triggerLink()} className="academyReviewBtn pd" >Join now</a>
                        </center>
                    </div>
                </section>

                <section className="academyReady">
                    <div className="academyContainer">
                        <div className="academyReadyBox">
                            <img src={AcademyLogo2} alt="" className="academyReadyLogo" data-aos="fade-up" data-aos-offset="50" />
                            <h3 className="academyReadySubtitle" data-aos="fade-up" data-aos-offset="50">READY TO START?</h3>
                            <p className="academyReadyText" data-aos="fade-up" data-aos-offset="50">Our modern, practical approach to teaching has led to tens of thousands of our students generating money from anywhere in the world.</p>
                            <div className="academyReadyBott" data-aos="fade-up" data-aos-offset="50">
                                <a onClick={() => triggerLink()} className="academyReadyBtn pd">Join now</a>
                                <p className="academyReadyRemark">LOCK IN YOUR PRICE BEFORE IT INCREASES</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="academyAccess" id="access">
                    <div className="academyContainer">
                        <div className="academyAccessHeading">
                            <img src={academyLock} alt="" />
                            <span>YOU WILL GET ACCESS TO</span>
                        </div>
                        <div className="academyAccessRow mt0">
                            <div className="academyAccessInfo">
                                <span className="academyAccessSubtitle" data-aos="fade-up" data-aos-offset="50">Step-by-step learning</span>
                                <p className="academyAccessText" data-aos="fade-up" data-aos-offset="50">You will get access to 300+  courses and well-structured tutorials covering everything from the fundamentals of modern business to niche money-making strategies.</p>
                                <ul className="academyAccessList">
                                    <li data-aos="fade-up" data-aos-offset="50">
                                        <span>Easy-to-follow program for financial success</span>
                                    </li>
                                    <li data-aos="fade-up" data-aos-offset="50">
                                        <span>Quickly learn new high income skills</span>
                                    </li>
                                    <li data-aos="fade-up" data-aos-offset="50">
                                        <span>Hyper advanced learning application</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="academyAccessImage" data-aos="fade-left" data-aos-offset="50">
                                <img src={academyAccess1} alt="" />
                            </div>
                        </div>
                        {/* <div className="academyAccessRow">
                            <div className="academyAccessUsers" data-aos="fade-right" data-aos-offset="50">
                                <div className="academyAccessUser">
                                    <img src={academyMan1} alt="" />
                                </div>
                                <div className="academyAccessUser">
                                    <img src={academyMan2} alt="" />
                                </div>
                                <div className="academyAccessUser">
                                    <img src={academyMan3} alt="" />
                                </div>
                                <div className="academyAccessUser">
                                    <img src={academyMan4} alt="" />
                                </div>
                                <div className="academyAccessUser">
                                    <img src={academyMan5} alt="" />
                                </div>
                                <div className="academyAccessUser">
                                    <img src={academyMan6} alt="" />
                                </div>
                            </div>
                            <div className="academyAccessInfo small" data-aos="fade-left" data-aos-offset="50">
                                <span className="academyAccessSubtitle">Daily interactive sessions</span>
                                <p className="academyAccessText">Our instructors are experienced and motivated professionals ready to provide you with structured coursework, daily lessons, and continuous guidance throughout your entrepreneurial journey.</p>
                                <p className="academyAccessText">They will support you at every stage of your development, from mastering the fundamentals to achieving success in business. Our educational program is unique and recognized as a true investment in your future."</p>
                            </div>
                        </div> */}
                        <div className="academyAccessRow">
                            <div className="academyAccessInfo">
                                <span className="academyAccessSubtitle" data-aos="fade-up" data-aos-offset="50">An exclusive community with over 10,000 like-minded students.</span>
                                <p className="academyAccessText" data-aos="fade-up" data-aos-offset="50">Our online community is a supportive and purpose-driven environment. Everyone shares the same mission: to create a profitable business.</p>
                                <ul className="academyAccessList">
                                    <li data-aos="fade-up" data-aos-offset="50">
                                        <span>Connect with fellow travelers on your financial journey.</span>
                                    </li>
                                    <li data-aos="fade-up" data-aos-offset="50">
                                        <span>Celebrate your victories with people who understand your ambitions.</span>
                                    </li>
                                    <li data-aos="fade-up" data-aos-offset="50">
                                        <span>A network of over 10,000 individuals pursuing the same mission."</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="academyAccessImage ml" data-aos="fade-left" data-aos-offset="50">
                                <img src={academyAccess2} alt="" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="academyTarifs">
                    <div className="academyContainer">
                        <h2 className="academyTarifsTitle" data-aos="fade-up" data-aos-offset="50">THE CHOICE IS <span>YOURS</span></h2>
                        <div className="academyTarifsBox">
                            <div className="academyTarifsItem" data-aos="fade-up" data-aos-offset="50">
                                <span className="academyTarifsName">TAKE ACTION</span>
                                <p className="academyTarifsSubtitle">You need to act now.</p>
                                <div className="academyTarifsPrices">
                                    <span className="academyTarifsOld">$99</span>
                                    <span className="academyTarifsNew">$24.99</span>
                                </div>
                                <ul className="academyTarifsList">
                                    <li><span>Simple-step-by-step tutorials</span></li>
                                    <li><span>300 courses on entrepreneurship</span></li>
                                    <li><span>Access to millionaire mentors</span></li>
                                    <li><span>Community chat groups</span></li>
                                    <li><span>No experience needed</span></li>
                                    <li><span>Custom made learning application</span></li>
                                    <li className="shield"><span>Cancel anytime, risk-free</span></li>
                                    <li className="lock"><span>$24.99/month forever</span></li>
                                </ul>
                                <a onClick={() => triggerLink()} className="academyTarifsBtn">join FeelGrow app</a>
                                <p className="academyTarifsRemark">LOCK IN YOUR PRICE BEFORE IT INCREASES</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="academyPrice">
                    <div className="academyContainer">
                        <img src={AcademyLogo3} alt="" className="academyPriceLogo" data-aos="fade-up" data-aos-offset="50" />
                        <p className="academyPriceSubtitle" data-aos="fade-up" data-aos-offset="50">WARNING: PRICE INCREASING SOON</p>
                        <p className="academyPricePrice" data-aos="fade-up" data-aos-offset="50">LOCK-IN YOUR PRICE <br /> <span>OF $24.99</span></p>
                        <div className="academyPriceWarning" data-aos="fade-up" data-aos-offset="50">
                            <img src={academyWarning} alt="" />
                            <span>The price will increase to <b>$99</b> a month, it’s your last chance.</span>
                        </div>
                        <p className="academyPriceText" data-aos="fade-up" data-aos-offset="50">thousands of students have already joined FeelGrow App and are on their way to financial freedom. Join now before the price increases to $147 a month. Don’t miss out on this opportunity.</p>
                        <center>
                            <a onClick={() => triggerLink()} className="academyPriceBtn" >join FeelGrow app</a>
                        </center>
                    </div>
                </section>

                <section className="academyAbout" id="about">
                    <div className="academyContainer">
                        <h2 className="academyAboutTitle" data-aos="fade-up" data-aos-offset="50">Who created FeelGrow ?</h2>
                        <div className="academyAboutItem" data-aos="fade-up" data-aos-offset="50">
                            <div className="academyAboutInfo">
                                <p className="academyAboutSubtitle">Andrew Filatov <br /> Founder and CEO of AEER Platform Inc</p>
                                <div className="academyAboutImage mob">
                                    <img src={academyOwner} alt="" />
                                </div>
                                <p className="academyAboutText">"I am a serial entrepreneur, but I'm not a public persone or a blogger. My expertise lies in creating and promoting viable business models. I invite you to join one of my top products designed for entrepreneurs."</p>
                                <div className="academyAboutSoc">
                                    <a href="https://www.linkedin.com/in/aeer/">
                                        <img src={linkedIn} alt="" />
                                    </a>
                                    <a href="https://instagram.com/andrew.aeer?igshid=OGQ5ZDc2ODk2ZA==">
                                        <img src={instagram} alt="" />
                                        <span>@andrew.aeer</span>
                                    </a>
                                </div>
                            </div>
                            <div className="academyAboutImage desk">
                                <img src={academyOwner} alt="" />
                            </div>
                            
                        </div>
                    </div>
                </section>

                <section className="academyFaq">
                    <div className="academyContainer">
                        <h2 className="academyFaqTitle" data-aos="fade-up" data-aos-offset="50">Frequently Asked Questions</h2>
                        <div className="academyFaqBox">
                            {
                                questions.map((key) => (
                                    <AcademyFAQItem key={key.id} data={key} />
                                ))
                            }
                        </div>
                        <center>
                            <a onClick={() => triggerLink()} className="academyPriceBtn">join FeelGrow app</a>
                        </center>
                    </div>
                </section>

                <footer className="academyFooter">
                    <div className="academyContainer">
                        <div className="academyFooterBox">
                            <div className="academyFooterLeft">
                                <div className="academyFooterUser">
                                    <img src={AvatarImage} alt="" />
                                </div>
                                <div className="academyFooterInfo">
                                    <span>CEO</span>
                                    <b>Andrew Filatov</b>
                                    <p>My goal is to create a product that helps people improve themselves</p>
                                </div>
                            </div>
                            <div className="academyFooterCenter">
                                <div className="academyFooterSoc desk">
                                    <a>
                                        <img src={masterCardV2} alt="" />
                                    </a>
                                    <a>
                                        <img src={VisaV2} alt="" />
                                    </a>
                                </div>
                                <div className="academyFooterPayment">
                                    <a onClick={() => triggerLink()}>
                                        <img src={academyGplay} alt="" />
                                    </a>
                                    <a onClick={() => triggerLink()}>
                                        <img src={academyAppstore} alt="" />
                                    </a>
                                </div>
                                <div className="academyFooterNav">
                                    <Link to="/terms">Terms of User</Link>
                                    <Link to="/privacy">Privacy policy</Link>
                                    <Link to="/cookie">Cookie policy</Link>
                                    <Link to="/cancel">Unsubscribe</Link>
                                </div>
                            </div>
                            <div className="academyFooterRight">
                                <div className="academyFooterSoc mob">
                                    <a>
                                        <img src={masterCardV2} alt="" />
                                    </a>
                                    <a>
                                        <img src={VisaV2} alt="" />
                                    </a>
                                </div>
                                <img src={BigLogoRight} alt="" className="academyFooterLogo" />
                                <p>© AEER PLATFORM INC <br />
                                8 THE GREEN STE A DOVER, DE 19901</p>
                            </div>
                        </div>
                    </div>
                </footer>
                
            </section>
            
        </main>
    )
}

export default academyPage

export const Head: HeadFC = () => <title>AEER Platform</title>
