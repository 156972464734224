import './styles.scss';
import React, {memo, useState} from 'react';
import parse from 'html-react-parser';
import {
    academyArrow
} from "../../images";

type Props = {
    data: {
        id: number,
        question: string,
        answer: string
    }
}

const AcademyFAQItem = ({ data }: Props) => {

    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(!isActive)
    }

    return (
        <div key={data.id} className="academyFaqItem" data-aos="fade-up" data-aos-offset="200" onClick={() => handleClick()}>
            <div className={`academyFaqHeading ${isActive ? 'active' : ''}`}>
                <span>{data.question}</span>
                <img src={academyArrow} alt="" />
            </div>
            <div className={`academyFaqText ${isActive ? 'active' : ''}`}>
                <p>{parse(data.answer)}</p>
            </div>
        </div>
    )
}

export default memo(AcademyFAQItem);
